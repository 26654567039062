@import "../../style/_colors";
@import "../../style/_mixins";

.fixed {
  position: fixed;
  top: -112px;
  width: 100%;
  background: $feedback-form-date;
  backdrop-filter: blur(30px);
  z-index: 5;
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;

  .headerContainer {
    align-items: center;
    width: 100%;
  }
}

.fixed_2 {
  top: 0;
}

.transition {
  transition: all 0.5s;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    height: 112px;
    max-width: 1440px;
    padding: 0 96px;
    margin: 0 auto;

    .logo {
      display: flex;
      color: $text-color;
      text-decoration: none;
    }

    .logo > img {
      width: 223px;
      height: 46px;
      cursor: pointer;
    }

    .navList {
      display: flex;
      align-items: center;

      .languageLinks {
        margin: 0 10px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 5px;

        .languageLink {
          color: $text-color;
          text-decoration: none;
          font-size: 20px;
          line-height: 38px;
          font-weight: 500;
          cursor: pointer;
        }

        .languageLink:nth-child(2) {
          line-height: 38px;
          font-weight: 400;
        }

        .active {
          color: $text-color-opacity-5;
        }
      }

      .hoverText {
        position: relative;
      }

      .menuVector1,
      .menuVector2 {
        width: 0;
        height: 34px;
        border-right: 1px solid $text-color-opacity-6;
      }

      .menuVector2 {
        display: none;
      }

      .navItem {
        margin: 0 20px;
        color: $text-color;
        text-decoration: none;
        cursor: pointer;
      }

      .navItem:nth-child(5) {
        margin-right: 24px;
      }

      .navBtn {
        @include button;
        height: 47px;
        margin-left: 24px;
        padding: 12px 24px;
        gap: 10px;
        border-radius: 8px;
      }

      .navBtn:hover {
        @include button-hover;
      }

      .navBurger {
        display: none;
        margin-left: 16px;
        cursor: pointer;
      }

      .hoverText {
        position: relative;
      }

      .hoverText:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 4px;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        background-color: $primary-color;
        transform-origin: bottom left;
        transition: transform 0.15s ease-out;
      }

      .hoverText:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }

    .menu {
      position: fixed;
      opacity: 0;
      transform: translateY(100vh);
      will-change: transform, opacity;
      transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1), opacity 0s 0.5s,
        -webkit-transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 0;
      top: 0;
      min-height: 0;
      background-color: transparent;
      padding-top: 65px;
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0;
      }

      &_active {
        width: 100%;
        min-height: 100vh;
        opacity: 1;
        transform: translateY(0vh);
        transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1),
          -webkit-transform 0.5s cubic-bezier(0.25, 1, 0.5, 1),
          background-color 0.5s;
        background-color: #000;
      }

      &_content {
        position: relative;
        background-color: $dark-grey-card;
        border-radius: 24px 24px 0 0;
        overflow: hidden;
        height: fit-content;
        z-index: 0;
        min-height: 100%;
      }

      .menuHeader {
        display: flex;
        justify-content: space-between;

        .logo {
          display: flex;
          align-items: center;
          margin-left: 50px;

          img {
            width: 223px;
            height: 46px;
          }
        }

        .closeIcon {
          width: 40px;
          height: 40px;
          margin: 40px 60px;
          cursor: pointer;
        }
      }

      .menuBody {
        display: flex;
        align-items: center;
        height: 80%;
        z-index: 1;

        .menuBlock {
          display: flex;
          justify-content: space-around;
          width: 100%;
          margin: 45px 0 200px;

          .menuPannel {
            display: flex;
            flex-direction: column;
            gap: 55px;

            .burgerTextLink {
              font-weight: 500;
              line-height: 68px;
              color: $text-color;
              text-decoration: none;
              width: fit-content;
              cursor: pointer;
              font-size: 56px;
            }

            .burgerLanguageLink {
              cursor: pointer;
              color: $text-color;
              margin-right: 32px;
              text-decoration: none;
              font-size: 32px;
              line-height: 38px;
              font-weight: 500;
            }

            .burgerLanguageLink:nth-child(2) {
              margin-right: 0;
              line-height: 38px;
              font-weight: 400;
            }

            .active {
              color: $text-color-opacity-5;
            }

            .hoverText {
              position: relative;
            }

            .hoverText:after {
              content: "";
              position: absolute;
              width: 100%;
              transform: scaleX(0);
              height: 4px;
              bottom: 0;
              left: 0;
              border-radius: 2px;
              background-color: $primary-color;
              transform-origin: bottom left;
              transition: transform 0.15s ease-out;
            }

            .hoverText:hover:after {
              transform: scaleX(1);
              transform-origin: bottom left;
            }

            .contactBlock {
              display: none;

              .footerPhone,
              .footerEmail {
                margin-top: 25px;
                font-size: 40px;
                font-weight: 500;
                line-height: 49px;
                a {
                  text-decoration: none;
                  color: $text-color;
                }
              }

              .footerEmail {
                margin-top: 32px;
              }
            }
          }

          .menuPannel:nth-child(1) {
            margin-left: 56px;
            align-self: flex-start;
          }
        }
      }

      .burgerBackground {
        position: absolute;
        right: -125px;
        top: 570px;
        width: 354px;
        height: 354px;
        background: $primary-color-opacity-7;
        filter: blur(150px);
        z-index: -1;
      }
    }
  }
}

@media screen and (max-width: 1439px) {
  header {
    padding: 0 40px;

    .headerContainer {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1164px) {
  header {
    .headerContainer {
      height: 97px;

      .navList {
        .languageLinks,
        .navItem,
        .menuVector1 {
          display: none;
        }

        .menuVector2,
        .navBurger {
          display: block;
        }

        .navBtn {
          margin-right: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  header {
    .headerContainer {
      .menu {
        .menuBody {
          margin: 280px 0;
          align-items: flex-start;

          .menuBlock {
            justify-content: space-between;

            .menuPannel {
              gap: 40px;

              .startWork {
              }

              .contactBlock {
                display: block;
              }
            }

            .menuPannel:nth-child(2) {
              margin-right: 78px;
            }

            .socialContainer {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  header {
    .headerContainer {
      .menu {
        .menuHeader {
          position: relative;

          .logo {
            margin: 10px 0 0 45px;
          }

          .closeIcon {
            margin: 40px 38px;
          }
        }

        .menuBody {
          margin: 25px 0 96px;

          .menuBlock {
            flex-direction: column;
            margin: 0;

            .menuPannel:nth-child(1),
            .menuPannel:nth-child(2) {
              margin-left: 64px;

              .burgerTextLink {
                font-size: 36px;
                line-height: 49px;
              }

              .socialContainer {
                margin-top: 25px;
              }
            }

            .menuPannel:nth-child(2) {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 743px) {
  header {
    padding: 0 16px;

    .headerContainer {
      height: 73px;

      .logo > img {
        width: 121px;
        height: 25px;
      }

      .navList > .navBurger {
        width: 48px;
      }

      .navBtn,
      .menuVector2 {
        display: none !important;
      }

      .menu {
        .menuHeader {
          .logo {
            margin: -15px 0 0 20px;

            img {
              width: 125px;
              height: 25px;
            }
          }
        }

        .menuBody {
          margin: 0 0 94px;

          .menuBlock {
            .menuPannel:nth-child(1),
            .menuPannel:nth-child(2) {
              margin-left: 24px;
              gap: 24px;

              .burgerTextLink {
                font-size: 32px;
                line-height: 39px;
              }

              .socialContainer {
                .contactBlock {
                  .footerEmail,
                  .footerPhone {
                    font-size: 24px;
                    line-height: 29px;
                    margin-top: 10px;
                  }

                  .footerEmail {
                    margin-top: 24px;
                  }
                }
              }
            }
          }
        }

        .burgerBackground {
          right: -300px;
        }
      }
    }
  }
}
